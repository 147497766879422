import { liteClient } from 'algoliasearch/lite';
import { envPrefixed } from './utils';

const ALGOLIA_APP_ID =
  typeof window !== 'undefined'
    ? window?.ENV?.ALGOLIA_APP_ID
    : process.env.ALGOLIA_APP_ID;
const ALGOLIA_SEARCH_API_KEY =
  typeof window !== 'undefined'
    ? window?.ENV?.ALGOLIA_SEARCH_API_KEY
    : process.env.ALGOLIA_SEARCH_API_KEY;

export const searchClient =
  ALGOLIA_APP_ID && ALGOLIA_SEARCH_API_KEY
    ? liteClient(ALGOLIA_APP_ID, ALGOLIA_SEARCH_API_KEY)
    : null;

export type SearchIndex = {
  indexName: string;
  type: string;
  label: string;
  fields: string[];
  highlightAttributeName: string;
  getLink: (hit: Record<string, string>) => string;
  getSubText?: (hit: Record<string, string>) => string;
};

export type SearchIndexes =
  | 'creditCards'
  | 'blogs'
  | 'tags'
  | 'creditCardCategories'
  | 'creditCardIssuers'
  | 'hotels'
  | 'tripReports';

type SearchIndexDefinitions = {
  [key in SearchIndexes]: SearchIndex;
};

export const searchIndexes: SearchIndexDefinitions = {
  creditCards: {
    indexName: envPrefixed('api::credit-card.credit-card'),
    type: 'Credit Card',
    label: 'Credit Cards',
    fields: ['id', 'cardName', 'issuerName'],
    highlightAttributeName: 'cardName',
    getLink: (hit) => `/credit-cards/${hit.slug}`,
  },
  tags: {
    indexName: envPrefixed('api::tag.tag'),
    type: 'Tag',
    label: 'Tags',
    fields: ['id', 'name', 'slug'],
    highlightAttributeName: 'name',
    getLink: (hit) => `/credit-cards/reviews/${hit.slug}`,
  },
  creditCardCategories: {
    indexName: envPrefixed('api::credit-card-category.credit-card-category'),
    type: 'Credit Card Category',
    label: 'Credit Card Categories',
    fields: ['id', 'name', 'slug'],
    highlightAttributeName: 'name',
    getLink: (hit) => `/cards/${hit.slug}`,
  },
  creditCardIssuers: {
    indexName: envPrefixed('api::credit-card-issuer.credit-card-issuer'),
    type: 'Card Issuer',
    label: 'Credit Card Issuers',
    fields: ['id', 'issuerName', 'slug'],
    highlightAttributeName: 'issuerName',
    getLink: (hit) => `/issuers/${hit.slug}`,
  },
  blogs: {
    indexName: envPrefixed('api::blog.blog'),
    type: 'Article',
    label: 'Articles',
    fields: ['id', 'title', 'subtitle', 'slug'],
    highlightAttributeName: 'title',
    getLink: (hit) => `/articles/${hit.slug}`,
    getSubText: (hit) => hit.subtitle,
  },
  hotels: {
    indexName: envPrefixed('api::hotel.hotel'),
    type: 'Hotel',
    label: 'Hotels',
    fields: ['id', 'name', 'slug'],
    highlightAttributeName: 'name',
    getLink: (hit) => `/hotels/${hit.slug}`,
    getSubText: () => '',
  },
  tripReports: {
    indexName: envPrefixed('api::trip-report.trip-report'),
    type: 'Trip Report',
    label: 'Trip Reports',
    fields: ['id', 'title', 'tagline', 'slug'],
    highlightAttributeName: 'title',
    getLink: (hit) => `/trip-reports/${hit.slug}`,
    getSubText: (hit) => hit.tagline,
  },
};
